<template>
  <el-row>
    <el-col :span="3" :offset="1">
      <el-select v-model="clientId" :placeholder="$t('view.billing.clients')" filterable>
        <el-option
          v-for="client in clients"
          :key="client.id"
          :label="client.name"
          :value="client.id" />
      </el-select>
    </el-col>

    <el-col :span="(state) ? 11 : 3" class="flex-spacer">
      <el-switch
        v-model="state"
        :active-text="$t('view.billing.previous')">
      </el-switch>

      <el-select v-model="month" filterable v-show="state">
        <el-option
          v-for="month in months"
          :key="month.value"
          :label="month.text"
          :value="month.value" />
      </el-select>

      <el-select v-model="year" filterable v-show="state">
        <el-option
          v-for="year in years"
          :key="year.value"
          :label="year.text"
          :value="year.value" />
      </el-select>
    </el-col>

    <el-col :span="(state) ? 2 : 12">
      <el-button @click="loadStats" :disabled="!clientId">{{ $t('view.billing.load') }}</el-button>
    </el-col>

    <el-col :span="10" :offset="1">
      <h2 class="text-center">{{ $t('view.billing.networks.title') }}</h2>
      <highcharts :options="chartOptionsNetworks" v-if="chartOptionsNetworks"></highcharts>
    </el-col>
    <el-col :span="10" :offset="1">
      <h2 class="text-center">{{ $t('view.billing.projects.title') }}</h2>
      <highcharts :options="chartOptionsProjects" v-if="chartOptionsProjects"></highcharts>
    </el-col>
    <el-col :span="12" :offset="1">
      <h2 class="text-center">{{ $t('view.billing.resume.title') }}</h2>
        <el-table
          stripe
          lazy
          :data="projects">
          <el-table-column
            :label="$t('view.billing.resume.project')"
            width="250">
            <template slot-scope="scope">
              <span>
                {{ scope.row.name }}
                <span v-if="scope.row.deleted">
                  ({{ $t('view.billing.resume.deleted') }})
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('view.billing.resume.twitter')"
            align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.data[1].count | NumberFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('view.billing.resume.facebook')"
            align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.data[2].count | NumberFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('view.billing.resume.instagram')"
            align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.data[3].count | NumberFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('view.billing.resume.news')"
            align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.data[4].count | NumberFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('view.billing.resume.total')"
            align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.data[0].count | NumberFormat }}</span>
            </template>
          </el-table-column>
        </el-table>
    </el-col>

    <el-col :span="8" :offset="1">
      <h2 class="text-center">{{ $t('view.billing.plan.title') }}</h2>

      <h4 class="text-center">{{ $t('view.billing.plan.period') }}</h4>
      <el-table :data="period_data" :show-header="false" stripe>
        <el-table-column prop="title"></el-table-column>
        <el-table-column prop="text">
          <template slot-scope="scope">
            <span>{{ scope.row.date | DatetimeFormat(true) }} (UTC)</span>
          </template>
        </el-table-column>
      </el-table>

      <h4 class="text-center">{{ $t('view.billing.plan.details') }}</h4>
      <el-table :data="quote" :show-header="false" stripe>
        <el-table-column prop="title"></el-table-column>
        <el-table-column prop="text">
          <template slot-scope="scope">
            <span v-if="scope.row.title === $t('view.billing.plan.name')">
              {{ scope.row.text }}
            </span>
            <span v-else-if="scope.row.title === $t('view.billing.plan.excess')">
              <template v-if="scope.row.text === '0'">{{ $t('commons.no') }}</template>
              <template v-else>{{ $t('commons.yes') }}</template>
            </span>
            <el-progress
              :text-inside="true"
              :stroke-width="26"
              :percentage="scope.row.text"
              v-else-if="scope.row.title === $t('view.billing.plan.percentage')" />
            <span v-else>
              {{ scope.row.text | NumberFormat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
import Moment from 'moment'
import { DatetimeFormat } from '@/filters/date-format'
import NumberFormat from '@/filters/number-format'

export default {
  name: 'Billing',
  data () {
    return {
      clients: null,
      clientId: null,
      state: false,
      month: null,
      months: null,
      year: null,
      years: [],
      projects: null,
      period: null,
      chartOptionsNetworks: null,
      chartOptionsProjects: null,
      period_data: [
        {
          title: this.$t('view.billing.plan.since'),
          date: 'DD/MM/YYYY HH:mm:ss'
        },
        {
          title: this.$t('view.billing.plan.until'),
          date: 'DD/MM/YYYY HH:mm:ss'
        }
      ],
      quote: [
        { title: this.$t('view.billing.plan.name'), text: '' },
        { title: this.$t('view.billing.plan.quota'), text: 0 },
        { title: this.$t('view.billing.plan.total'), text: 0 },
        { title: this.$t('view.billing.plan.surplus'), text: 0 },
        { title: this.$t('view.billing.plan.percentage'), text: 0 },
        { title: this.$t('view.billing.plan.excess'), text: 0 }
      ]
    }
  },
  watch: {
    state (value) {
      if (value) {
        this.setMonth()
        this.setYears()
      }
    },
    year () {
      this.setMonth()
    }
  },
  methods: {
    async loadClients () {
      try {
        const vm = this
        const res = await vm.$http.get('client/list/bill')

        console.log(res)
        vm.$set(vm, 'clients', res.data)
        // vm.$set(vm, 'clientId', res.data[0].id)
      } catch (error) {
        console.log(error)
      }
    },
    async loadStats () {
      const vm = this

      const loading = vm.$loading()

      if (!vm.clientId) {
        alert('Debe haber un cliente seleccionado antes de consultar')
        return
      }

      try {
        const query = (!this.state) ? 'current=true' : `month=${vm.month}&year=${vm.year}`
        const url = `admin/billing/?clientId=${vm.clientId}&${query}`
        const result = await vm.$http.get(url)

        const data = result.data

        vm.$set(vm.period_data[0], 'date', data.since)
        vm.$set(vm.period_data[1], 'date', data.until)

        vm.initGraph('chartOptionsNetworks', vm.calculateNetworks(data.projects))
        vm.initGraph('chartOptionsProjects', vm.calculateProjects(data.projects))

        vm.$set(vm, 'projects', vm.getSummaries(data.projects))
      } catch (error) {
        console.log(error)
        vm.$message.error('Ha ocurrido un error al intentar acceder al consumo')
      }

      loading.close()
    },
    initGraph (graph, data) {
      const vm = this
      const optionsBase = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [
          {
            name: vm.$t('view.billing.posts'),
            colorByPoint: true,
            data: data
          }
        ]
      }

      this.$set(this, graph, optionsBase)
    },
    getSummaries (stats) {
      const vm = this

      const chunk = Array.from(stats[0].data)
      const dataBlock = chunk.map(item => {
        return {
          source: item.source,
          count: 0
        }
      })

      const sum = {
        name: vm.$t('view.billing.resume.totals'),
        data: dataBlock
      }

      stats.forEach(item => {
        item.data.forEach((subitem, subindex) => {
          sum.data[subindex].count += subitem.count
        })
      })

      const plan = vm.clients.find(client => client.id === vm.clientId).plan

      stats.push(sum)

      const surplus = sum.data[0].count - plan.quota
      vm.$set(vm.quote[3], 'text', (surplus >= 1) ? surplus : 0)

      const percentage = Math.ceil((+sum.data[0].count * 100) / plan.quota)
      vm.$set(vm.quote[4], 'text', percentage)

      vm.$set(vm.quote[0], 'text', plan.name)
      vm.$set(vm.quote[1], 'text', plan.quota)
      vm.$set(vm.quote[2], 'text', sum.data[0].count)
      vm.$set(vm.quote[5], 'text', plan.excess)

      return stats
    },
    calculateProjects (stats) {
      const projectsTotal = stats.map(project => {
        const total = project.data.reduce((accumulator, current) => {
          return (current.source === 'total') ? 0 : accumulator + current.count
        }, 0)

        return {
          name: project.name,
          y: total
        }
      })

      return projectsTotal
    },
    calculateNetworks (stats) {
      const chunk = Array.from(stats[0].data).splice(1, stats[0].data.length)

      // Reiniciar contadores
      const networks = chunk.map(network => {
        return {
          name: network.source,
          y: 0
        }
      })

      stats.forEach(project => {
        project.data.forEach(network => {
          networks.forEach(accumulator => {
            if (accumulator.name === network.source) {
              accumulator.y += network.count
            }
          })
        })
      })

      return networks
    },
    setMonth () {
      const vm = this
      const months = []
      Moment.months().forEach((element, index) => {
        const month = ++index

        if (vm.year === Moment().year()) {
          if (month <= Moment().month() + 1) {
            months.push({
              value: (month < 10) ? `0${month}` : month,
              text: element.charAt(0).toUpperCase() + element.slice(1)
            })
          }
        } else {
          months.push({
            value: (month < 10) ? `0${month}` : month,
            text: element.charAt(0).toUpperCase() + element.slice(1)
          })
        }
      })

      this.$set(this, 'months', months)
      this.$set(this, 'month', Moment().format('MM'))
    },
    setYears () {
      const firstYear = 2017
      const currentYear = +Moment().year()
      const lastYears = []

      for (let index = firstYear; index <= currentYear; index++) {
        const data = {
          value: index,
          text: index.toString()
        }

        lastYears.push(data)
      }

      this.$set(this, 'year', currentYear)
      this.$set(this, 'years', lastYears)
    }
  },
  async mounted () {
    await this.loadClients()
  },
  filters: {
    DatetimeFormat,
    NumberFormat
  }
}
</script>
<style lang="sass">
.flex-spacer
  align-items: center
  display: flex
  justify-content: space-around
  height: 40px

.el-progress-bar__inner
  max-width: 100%
</style>
